import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Manager',
    component: () => import('../views/Manager.vue'),
    redirect: to => {
      const user = JSON.parse(localStorage.getItem("xm-user") || '{}');
      return user && user.role ? '/home' : '/login';
    },
    children: [
      { path: '403', name: 'NoAuth', meta: { name: '无权限' }, component: () => import('../views/manager/403') },
      { path: 'home', name: 'Home', meta: { name: '系统首页',roles: ['ADMIN','USER']  }, component: () => import('../views/manager/Home') },
      { path: 'admin', name: 'Admin', meta: { name: '管理员信息',roles: ['ADMIN'] }, component: () => import('../views/manager/AdminAdmins') },
      { path: 'user', name: 'User', meta: { name: '用户信息',roles: ['ADMIN'] }, component: () => import('../views/manager/AdminUsers') },
      { path: 'adminPerson', name: 'AdminPerson', meta: { name: '个人信息',roles: ['ADMIN','USER'] }, component: () => import('../views/manager/AdminSettings') },
      { path: 'password', name: 'Password', meta: { name: '修改密码',roles: ['ADMIN','USER'] }, component: () => import('../views/manager/Password') },
      { path: 'notice', name: 'Notice', meta: { name: '公告信息',roles: ['ADMIN','USER']  }, component: () => import('../views/manager/Notice') },
      { path: 'quest', name: 'Quest', meta: { name: '大型任务',roles: ['ADMIN','USER']  }, component: () => import('../views/manager/Quest') },
      { path: 'editor', name: 'Editor', meta: { name: '单页公告' ,roles: ['ADMIN','USER'] }, component: () => import('../views/manager/Editor')},
      { path: 'templateGallery', name: 'TemplateGallery', meta: { name: '模板库' ,roles: ['ADMIN','USER'] }, component: () => import('../views/manager/TemplateGallery')},
      { path: 'delete', name: 'DeleteMisc', meta: { name: '清除缓存' ,roles: ['ADMIN'] }, component: () => import('../views/manager/DeleteMisc')},
      { path: 'document', name: 'Document', meta: { name: '新手指南' ,roles: ['ADMIN','USER'] }, component: () => import('../views/manager/Document') },

      { path: 'check', name: 'Check', meta: { name: '公告审核' ,roles: ['ADMIN','USER'] }, component: () => import('../views/manager/Check') },
      { path: 'upload', name: 'Upload', meta: { name: '下载所有' ,roles: ['ADMIN'] }, component: () => import('../views/manager/Upload') },
    ]
  },
  { path: '/login', name: 'Login', meta: { name: '登录' }, component: () => import('../views/Login.vue') },
  { path: '/loading', name: 'Loading', meta: { name: '加载界面' }, component: () => import('../views/Loading.vue') },
  { path: '*', name: 'NotFound', meta: { name: '无法访问' }, component: () => import('../views/NotFound.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 注：不需要前台的项目，可以注释掉该路由守卫
// 路由守卫
router.beforeEach((to ,from, next) => {
  const requirement = to.meta.roles
  let user = JSON.parse(localStorage.getItem("xm-user") || '{}');

  if (!user || !user.role) {
    if (to.path !== '/login') {
      next('/login');
      return;
    }
  }

  if (requirement && requirement.indexOf(user.role) === -1) {
    next('/login'); // Better to send unauthorized users to login or an error page
  } else {
    next();
  }
})

export default router
