import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI, {MessageBox} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/global.css'
import '@/assets/css/theme/index.css'
import request from "@/utils/request";
import '@/assets/css/font.css';
import axios from 'axios';
Vue.config.productionTip = false
Vue.prototype.$request = request

//build前需要改的地方1
Vue.prototype.$baseUrl = process.env.VUE_APP_BASEURL
Vue.prototype.$axios = axios

Vue.use(ElementUI, {size: "small"})

import thumbsupImage from './assets/imgs/thumbsup.png'
import sleepImage from './assets/imgs/sleep.png'

Vue.prototype.$showSuccessMessage = function() {
    MessageBox.alert(
        `<div style="text-align: center;">
      <img src="${thumbsupImage}" alt="Success" style="width: 200px;"><br>
      <p style="font-family: NotoSansSC;color: black;font-size: 25px">操作成功！</p>
    </div>`,
        '', {
            dangerouslyUseHTMLString: true,
            confirmButtonText: 'OK',
        }
    ).catch(() => {});
};
Vue.prototype.$showErrorMessage = function() {
    MessageBox.alert(
        `<div style="text-align: center;">
      <img src="${sleepImage}" alt="Error" style="width: 200px;"><br>
      <p style="font-family: NotoSansSC;color: black;font-size: 25px">哎呀，出BUG了！请联系HazelM！</p>
    </div>`,
        '', {
            dangerouslyUseHTMLString: true,
            confirmButtonText: 'OK',
        }
    ).catch(() => {});
};
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')

